import { getConfig } from '@/config/config.ts';
import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

export function HelpCenter(): JSX.Element {
  return (
    <aside>
      <SgwtHelpCenter
        applicationId={import.meta.env.VITE_ID}
        mailSubject={getConfig().helpCenter.subject}
        default-send-to={getConfig().helpCenter.contact}
        allowScreenshot={true}
      />
    </aside>
  );
}
