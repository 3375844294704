import { type ReactElement, useEffect, useState } from 'react';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { getUserConnection } from '@/sgwt/widgets.ts';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { type ApplicationDto } from '@/models/services.ts'
import { Link } from 'react-router-dom';


export type BookmarksProps = {
    bookmarks: ApplicationDto[] | null
  };

  
const Bookmarks: FC<BookmarksProps>= ({
    bookmarks,
}: BookmarksProps) => {

  const getBookmarkStatus= (status: string) => {
    return (
      `card card-raising bookmarkable-card-frame d-flex justify-content-center align-items-center p-0 display-4 bg-white border-${status=== 'Healthy' ? 'healthy' : status === 'Unhealthy' ? 'unhealthy' : 'degraded' }`
    );
  }

  return (
    <div className="row pt-2  d-flex">
        {bookmarks?.map((application) => (
            <div className="d-flex" style={{ width: 'auto' }}>
        
                <div className="card card-filling-secondary no-hover w-150">
                <div className="card-body d-flex align-items-center justify-content-center pt-2 pt-md-3 pb-2 px-0">
                    <div className={getBookmarkStatus(application.status)}>
                    <Link to={`/healths/${application.key}`} className="fw-semibold text-truncate mb-0">
                        <span className="fw-medium text-black">
                        {application.shortName}
                        </span>
                    </Link>
                    </div>
                </div>
                <div className="card-footer text-center p-0">
                    <label className="col text-primary fw-medium bookmarkable-icon-label">
                    <a target='blank' href={application.environments[0].urls[0]}>{application.name}</a>
                    </label>
                </div>
                </div>
            </div>
        ))}
        </div>
  );
};

export default Bookmarks;
