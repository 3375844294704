import { useLayoutEffect, useRef, type FC, } from 'react';
import { type DataGraph } from '@/models/dataGraph.ts';
import { type Connection } from '@/models/connection.ts';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3'


export type OrgChartComponentProps = {
  data: DataGraph[],
  connections: Connection[],
  showConnection: boolean,
  onNodeClick: (d: any) => void; 
};


const OrgChartComponent: FC<OrgChartComponentProps> = ({data, connections, showConnection, onNodeClick}: OrgChartComponentProps) => {

  const d3Container = useRef(null);
  let chart: any = null;

  
  const ChangeView = (layout: string): void => {
    chart.layout(layout).render().fit();
  }


    
  const filterChart = (e: any): void => {
    const value = e.currentTarget.value;
    chart.clearHighlighting();
    const data = chart.data();
    data.forEach((d) => {
      if (value != '' && d.name.toLowerCase().includes(value.toLowerCase())) {
        d._highlighted = true;
      }
    });
    chart.data(data).render().fit();
  }
  
    
  const updateWindow = (): void => {
      chart
      .svgWidth(window.innerWidth - 40)
      .svgHeight(window.innerHeight - 220)
          .render().fit();
    }
    window.onresize = updateWindow;

    // We need to manipulate DOM
    useLayoutEffect(() => {
      if (data && d3Container.current) {
        if (!chart) {
          chart = new OrgChart();
        }
        chart.nodeHeight((d: any) => 125 + 25)
        .nodeWidth((d: any) => 220 + 2)
        .svgWidth(window.innerWidth - 40)
        .svgHeight(window.innerHeight - 250)
        .childrenMargin((d) => 50)
        .compactMarginBetween((d) => 35)
        .compactMarginPair((d) => 30)
        .neighbourMargin((a, b) => 20)

        // .childrenMargin((d) => 50)
        // .compactMarginBetween((d) => 35)
        // .compactMarginPair((d) => 30)
        // .neighbourMargin((a, b) => 20)
  
        .linkUpdate(function(d: any, i: any, arr: any) {
          d3.select(this)
            .attr('stroke', d => d.data._upToTheRootHighlighted ? '#152785' : '#aaa')
  
            if (d.data._upToTheRootHighlighted) {
              d3.select(this).raise()
            }
        })
        .nodeContent(function (d: any, i: any, arr: any, state: any) {
          const color = '#FFFFFF';
          const imageDiffVert = 15 + 2;
          const links = d.data.links;
          
          const healthColor =  d.data.degraded ? '#FFE2C2' : d.data.unhealthy ? '#FFCECE' : '#DBF8DB';

          let height = d.height;
          d.data.degraded = d.data.status === 'Degraded' ? true : false;
          d.data.unhealthy = d.data.status === 'Unhealthy' ? true : false;
          //chart.setUpToTheRootHighlighted(d.data.id).render().fit();
          return `
                  <div style='width:${
                    d.width
                  }px;height:${height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px'>
                          <div style="font-family: 'Inter', sans-serif;margin-left:-1px;width:${d.width - 2}px;height:${height - imageDiffVert+7}px;border-radius:10px;; background-color: ${healthColor}; border: ${d.data.degraded ? '3px solid #ff8400' : d.data.unhealthy ? '3px solid #ff3333' : '1px solid #228B22'}" >
                            <div style="display:flex;justify-content:flex-end;margin-top:5px;margin-right:8px;color:#808080; color:transparent;">#${
                              d.data.id
                            }</div>
                            <div style="background-color:${color};margin-top:${-imageDiffVert - 25}px;margin-left:${15}px;border-radius:100px;width:40px;height:40px;" ></div>
                            <div style="margin-top:${
                              -imageDiffVert - 8
                            }px;"><img src=" ${d.data.type}" style="    margin-top: -10px;margin-left:${20}px;border-radius:10px;width:30px;height:30px;" /></div>
                              <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:10px">  ${
                                d.data.name
                              } </div>
                              <div style="color:${d.data.degraded ? '#FF8C00' : d.data.unhealthy ? '#ff3333' : '#228B22'};margin-left:20px;margin-top:3px;font-size:10px;font-weight:bold">${
                                d.data.status
                              } </div>
                              <div style="color:#228B22;margin-left:20px;margin-top:3px;font-size:10px;font-weight:bold;line-height:30px;height:30px;">
                              ${d.data.checks?.map(check => {
                                return `<img src="${check.icon}" style="margin-left:${10}px;border-radius:10px;width:30px;height:30px; display:'inline'}" title=${check.name} />`
                              }).join('')}
                              ${d.data.nodes?.map(node => {
                                return `<img src="/chart/node_${node}.svg" style="margin-left:${10}px;border-radius:10px;width:30px;height:30px; display:${node === 'undefined'? 'none':'inline'}" />`
                              }).join('')}
                              </div>
                              <div style="color:#228B22;margin-top:3px;font-size:10px;font-weight:bold">
                              <table style="border-top: 1px solid #E4E2E9; width: 100%; " >
                              <tr>
                                  ${links.map((item: any, index: any) => {
                                    return `<td><a href="${item.link}" target='_blank'><img src="/chart/${item.icon}" style="width:30px;height:30px;padding-top: 4px;" class="link" title="${item.name}" /></a></td>`
                                  }).join('')}
                              </tr>
                              </table>
                              </div>
  
                          </div>
                      </div>
                              `;
        })
          .container(d3Container.current)
          .data(data)
          .onNodeClick((d: any, i: any, arr: any) => {
            console.log(d, 'Id of clicked node ');
            onNodeClick(d);
          })
          .linkGroupArc(function linkValue({ source, target }) {
            const linkGenerationFunc = d3
              .linkHorizontal()
              .x((d: any) => d.x)
              .y((d: any) => d.y);
    
            return linkGenerationFunc({
              source: { x: source.x, y: source.y + 155 },
              target: { x: target.x, y: target.y - 135 },
            });
          })
          .expandAll().fit();
          if (showConnection)
          {
            let array: any = []
            connections.map((connection: Connection) => {
              console.log(connection.from +":"+connection.to);
              array.push({'from': connection.from, 'to': connection.to, 'label':""});
            });
            chart.connections(array);
          }
          chart.render();
      }
    }, [data, d3Container.current, showConnection]);
  
    return (
      <div >
        <div className='btn-layout-options'>
          <div className="btn-group btn-group-toggle btn-layout-horizontal" data-toggle="buttons" role="group" aria-label="toggle buttons single select">
            <label className="label-layout">Layout: </label>
            <input className="btn-check" type="radio" name="infordiob123" id="infordiob123-1b"  checked onClick={() => ChangeView('top')} />
            <label className="btn btn-toggle-info" htmlFor="infordiob123-1b">Vertical</label>
            <input type="radio" className="btn-check" name="infordiob123" id="infordiob123-2b"  onClick={() => ChangeView('left')} />
            <label className="btn btn-toggle-info" htmlFor="infordiob123-2b">Horizontal</label>
          </div>
          <div className="btn-group btn-group-toggle btn-search" data-toggle="buttons" role="group" aria-label="toggle buttons single select">
            <label className="label-layout">Search: </label>
            <input type="text" onChange={filterChart} />
          </div>
        </div>
        {/* <Button className='btn btn-round btn-info btn-layout-horizontal btn btn-secondary' onClick={() => ChangeView('left')} id="btnLayoutRight">Layout: Horizontal</Button>
        <Button className='btn btn-round btn-info btn-layout-vertical btn btn-secondary' onClick={() => ChangeView('top')} id="btnLayoutRight">Layout: Vertical</Button> */}
        <div ref={d3Container} />
      </div>
    );
};

export default OrgChartComponent;