import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type HealthDto } from '@/models/health.ts'
import { getConfig } from '@/config/config.ts';


export class HealthService {
  get(search?: string): Promise<HealthDto> {

    const { apiUrl } = getConfig();
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;

    return fetch(`${apiUrl}/api/v2/healths/${search}`, requestOptions)
    .then((_response: Response) => {
        if( _response.ok)
          return _response.json();
        else {
          if (_response.status == 404)
            throw new Error('Url not found');
          else 
            throw new Error('Healthcheck is not in a recognized json format.');
        }
    });
  }
}