import { type ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { HealthGraphService } from '@/services/HealthGraphService.ts'

import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import { type HealthGraphDto, type ServiceGraphDto } from '@/models/healthGraph.ts'
import Loading from '../common/Loading.tsx';
import { Link } from 'react-router-dom';
import './healthChart.css';
import Breadcrumb from '../../components/common/Breadcrumb.tsx'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label, Input, Nav, NavLink, NavItem,TabContent,TabPane, Row, Col, Card, CardTitle, CardText  } from 'reactstrap';
import HealthTree from './healthTree.tsx'

export function HealthChart(): ReactElement {

    let { key } = useParams();
    let { env } = useParams();
    
    const [application, setApplication] = useState<ApplicationDto>();
    const [currentEnvironment, setCurrentEnvironment] = useState<string>();
    const [healthGraphDto, setHealthGraphDto] = useState<ServiceGraphDto>();
    const [Authorized, setAuthorized] = useState<boolean>(false);
    const [LoadingSt, setLoading] = useState<boolean>(true);
    const [showConnection, setShowConnection] = useState<boolean>(false);
    
    const refreshServiceData = (): void => {
        new ApplicationsService().get().then((applications: ApplicationsDto) => {
            const app = applications?.webApps
                ?.filter(
                  (f) =>
                    f.key == key
                )
            setApplication(app[0]);
            console.log('env:'+env);
            let currentEnv = env;
            if (env == undefined)
                currentEnv =  app[0].environments[0].name
            setCurrentEnvironment(currentEnv);
            new HealthGraphService().get(key, currentEnv).then((healthGraph: HealthGraphDto) => {
                setAuthorized(true);
                setHealthGraphDto(healthGraph.service)
            })
            .catch((reason: any) => {
                setAuthorized(false);
                //window.location.href = "https://shared.sgmarkets.com/unauthorized/?redirectUri=https://status-dev.sgmarkets.com";
            })
            .finally( () => {
                setLoading(false);
            })

        });
    }
    
    useEffect(() => {
        refreshServiceData();
    }, []);
    
  
    const changeViewConnection = (): void => {
        setShowConnection(!showConnection);
    }


    return (

        <div>
            {LoadingSt === true ? <Loading isLoading= {LoadingSt} />
            :
            Authorized == true ? 
            <>
                <Breadcrumb pageName={application?.name!} parentName='Home' parentLink='/' />
                <Nav tabs>
                    {application?.environments.map((environment) =>(
                        <NavItem key={`nav_${environment.name}`} >
                            <NavLink href={`/healths/${key}/${environment.name}`} key={environment.name} className={currentEnvironment == environment.name ? "active" : '' }>{environment.name}</NavLink>
                        </NavItem>
                    ))
                    }
                </Nav>
                <div className='healthTreeContainer'>
                    <HealthTree service={healthGraphDto!} showConnection={showConnection} refreshServiceData={refreshServiceData} />
                    <Button className='btn btn-round btn-info btn-connections' onClick={ changeViewConnection }>View all connections</Button>
                </div>
            </> : 
            <>
            <section className="container mt-5">
                <div className="row d-flex justify-content-center py-5">
                    <div className="col-md-6 text-center">
                    <div className="d-flex align-items-center flex-column w-100">
                        <div className="rounded-circle bg-primary-alpha-lg d-flex align-items-center justify-content-center mb-4" style={{ width: " 5rem", height: " 5rem",  }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="48" viewBox="0 0 24 24">
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path fill="#30333" xmlns="http://www.w3.org/2000/svg" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                        </svg>
                        </div>
                        <h3 className="text-center" id="i18n-title">You are not authorized to access this service</h3>
                    </div>
                    <p className="mt-3 mb-5">
                        <span id="app-name" className="font-weight-bold">This specific drill down view of  SG|Markets Status </span>
                        <span id="i18n-accessRights">requires specific access rights.
                        <span id="sgm-access-button-info" className="">
                        <span id="i18n-sgmAccessLeft">You need to request profils 'Advanced User' for resources 'SGM Status' in SG|IAM</span>
                        <span id="i18n-sgmAccessRightsRight"> to register your interest for this service.</span>
                        </span>
                    </span></p>
                    <div id="content" className="d-none py-3"></div>
                    <div>
                        <Link to="/"  className="btn btn-lg btn-flat-secondary mt-2">Go Back</Link>
                    </div>
                    </div>
                </div>
                </section>
            </>
            }
        </div>
    );
}