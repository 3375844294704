import type { ServiceGraphDto, ApplicationLink } from '@/models/healthGraph.ts';
import { type FC, type ReactNode } from 'react';
import { useState } from 'react';
import { Modal, Button } from 'reactstrap';
import EditLinksModal from "./editLinksModal.tsx"
import { LinksService } from "@/services/LinksService.ts";
import './panelDetails.css'

interface Props {
    service: ServiceGraphDto | undefined;
    closePanel: () => void;
    refreshData: () => void;
}

const ButtonPill: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <span
      className="text-primary fw-standard badge rounded-pill bg-light bg-opacity-40 d-flex align-items-center justify-content-center m-2 px-3"
      style={{ height: 48 }}
    >
      {children}
    </span>
  );
};

const PanelDetails: FC<Props> = ({
  service,
  closePanel,
  refreshData,
}: Props) =>
  
{

  const [modalSettings, setModalSettings] = useState(false);
  
  const EditLinks = () => {
    setModalSettings(!modalSettings);
  };
  const CloseModal = () => setModalSettings(!modalSettings);

  const SaveLinks = (newLinks: ApplicationLink[]) => {
    new LinksService().save(service!, newLinks);
    refreshData();
    CloseModal();
  };

  return  service != null ? 
  <div className='panel-bordered-chart'>
    <Button color="white float-end btn-icon btn-top" onClick={closePanel}>
        X
    </Button>
    <h6>{service.name}</h6>
    <hr></hr>
    {service.type == "web-apps" ? 
    <>
      <div className="row ">
        <div className="col-12">
            <label className="form-label mb-1" htmlFor="department">
              <span className="text-nowrap">Description:</span>
            </label>
            <div className="w-100" dangerouslySetInnerHTML={{__html: service.description!}}>
            </div>
        </div>
      </div>
    </> 
    :
    service.type.indexOf("Azure ") == 0 ? 
    <>
      <div className="row ">
        <div className="col-12">
        </div>
      </div>
    </> 
    :
    <>
    <div className="row mt-3">
        <div className="col-12">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="department">
              <span className="text-nowrap">Urls:</span>
            </label>
            <div className="w-100">
              <span role="button" id="department">
                <ul>
                {service.internetDeclaration?.expositionUrl != null ? <li><a href={service.internetDeclaration?.expositionUrl} target='_blank'>{service.internetDeclaration?.expositionUrl}</a></li> : null }
                {service.apiDeclaration?.accessPoint != 'none' ? <li><a href={service.apiDeclaration?.accessPoint} target='_blank'>{service.apiDeclaration?.accessPoint}</a></li> : null }
                {service.apiDeclaration?.baseEndPointUri != null ? <li><a href={service.apiDeclaration?.baseEndPointUri} target='_blank'>{service.apiDeclaration?.baseEndPointUri}</a></li> : null }
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>
    <div className="row mt-3">
        <div className="col-12">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="department">
              <span className="text-nowrap">Manager department</span>
            </label>
            <div className="w-100">
              <span role="button" id="department">
                {service.apiDeclaration?.organizationalUnit}
              </span>
            </div>
          </div>
        </div>
      </div>
    <div className="row mt-3">
        <div className="col-12">
          <div className="form-group w-100 mb-0 mt-2">
            <label className="form-label mb-1" htmlFor="manager">
              <span className="text-nowrap">Manager</span>
            </label>
            <div className="w-100">
              <span role="button" id="manager">
              {service.apiDeclaration?.manager}
              {service.apiDeclaration?.managerBackups.map(manager => <div>{manager}</div>)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">Application</span>
            </label>
            <div className="w-100">
              <span role="button" id="application">
                <a href={'https://developer.fr.world.socgen/explore/application/'+service?.apiDeclaration?.kearId} target='_blank'>{service?.apiDeclaration?.iAppliName}</a>
              </span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">SG Initiative</span>
            </label>
            <div className="w-100">
              <a href="/" id="sginitiative">
                Open project
              </a>
            </div>
          </div>
        </div>
      </div>

      
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">Exposition</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.exposition}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">Lifecycle</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.lifeCycle}
            </div>
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="application">
              <span className="text-nowrap">RTO</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.formattedRto}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group w-100 mb-0 mt-4">
            <label className="form-label mb-1" htmlFor="sginitiative">
              <span className="text-nowrap">RPO</span>
            </label>
            <div className="w-100">
              {service?.apiDeclaration?.formattedRpo}
            </div>
          </div>
        </div>
      </div>


      <div className="mt-4 d-flex flex-wrap">
        <ButtonPill><i className="icon icon-sm me-1">public</i><a href={`https://platformization.sgmarkets.world.socgen/explore/all?trigrams=${service?.apiDeclaration?.iAppliName}`} target='_blank'>Platformization</a></ButtonPill>
        {service?.apiDeclaration?.sourceRepository == null ? null : 
          <ButtonPill><i className="icon icon-sm me-1 github-icon"></i><a href={`${service?.apiDeclaration?.sourceRepository}`} target='_blank'>Github</a></ButtonPill>
        }
        <ButtonPill>
          <i className="icon icon-sm me-1">web_asset</i>
          <a href={`https://developer.sgmarkets.com/explore/api/${service?.apiDeclaration?.key}/v1/information`} target='_blank'>Api</a>
        </ButtonPill>
        {service?.apiDeclaration?.swaggerFileUri != null ?
        <ButtonPill>
          <i className="icon icon-sm me-1">web_asset</i>
          <a href={`${service?.apiDeclaration?.swaggerFileUri}`} target='_blank'>Swagger</a>
        </ButtonPill>
        : null
        }
      </div>
        <h6 className='mt-3'>Custom Links:</h6>
        <div className="mt-2 d-flex flex-wrap">
        <Button className='btn btn-icon-start btn-info btn-round' onClick={() => EditLinks()}>
          <i className="icon icon-sm me-1">dataset</i>
          <span>Manage Custom Links</span>
        </Button>
        </div>
      <h6 className='mt-3'>Health:</h6>
      <div className="mt-4 d-flex flex-wrap">
        { service?.healthUri != '' ? <a target='_blank' href={service?.healthUri!}><img src="/chart/health.svg" width={32} height={32}/>{service.healthUri}</a> : <></>}
      </div>
      <h6 className='mt-3'>Infrastructure:</h6>
      <div className="mt-4 d-flex flex-wrap">
        {service?.instances?.map(instance => {
          return instance.status == "Healthy" ? <div><span><i className="icon icon-md text-success">check_circle</i></span>{instance.name}</div> :
          instance.status == "Unhealthy" ? <div><span><i className="icon icon-md text-danger">check_circle</i></span>{instance.name}</div> : 
          <div><span><i className="icon icon-md text-warning">check_circle</i></span>{instance.name}</div>
        })}
      </div>
      </>}
      <Modal isOpen={modalSettings} size='xl'>
        <EditLinksModal service={service} closeModal={CloseModal} save={SaveLinks}/>
      </Modal>
  </div>
  : null;
}
export default PanelDetails;