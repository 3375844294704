import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Layout } from '../components/layout/RootLayout.tsx';
import { FullLayout } from '../components/layout/FullLayout.tsx';
import { HomePage } from '../pages/HomePage.tsx';
import { ServicesPage } from '../pages/ServicesPage.tsx';
import { DxChartPage } from '../pages/bySubscriptions/DxChartPage.tsx';
import { HealthsPage } from '../pages/HealthsPage.tsx';
import { HealthPage } from '../pages/HealthPage.tsx';

import { DxApiChartPage } from '../pages/bySubscriptions/DxApiChartPage.tsx';

import { PATHS } from './routes.tsx';

export const router = createBrowserRouter([
  {
    id: 'root',
    path: PATHS.root,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
    ]
  },
  {
    id: 'services',
    path: PATHS.services,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ServicesPage />,
      },
    ]
  },
  {
    id: 'dx-chart',
    path: PATHS.dxChart,
    element: <FullLayout />,
    children: [
      {
        index: true,
        element: <DxChartPage />,
      },
    ]
  },
  {
    id: 'healths',
    path: PATHS.healths,
    element: <FullLayout />,
    children: [
      {
        index: true,
        element: <HealthsPage />,
      },
    ]
  },
  {
    id: 'healthsEnv',
    path: PATHS.healthsEnv,
    element: <FullLayout />,
    children: [
      {
        index: true,
        element: <HealthsPage />,
      },
    ]
  },
  {
    id: 'dx-api-chart',
    path: PATHS.dxApiChart,
    element: <FullLayout />,
    children: [
      {
        index: true,
        element: <DxApiChartPage />,
      },
    ]
  },
  {
    id: 'health',
    path: PATHS.health,
    element: <FullLayout />,
    children: [
      {
        index: true,
        element: <HealthPage />,
      },
    ]
  },
  
]);